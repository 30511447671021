import { Injectable } from '@angular/core';
import Prismic from 'prismic-javascript';

@Injectable({
  providedIn: 'root'
})
export class PrismicService {
  private apiEndpoint = 'https://knoll-financial.cdn.prismic.io/api/v2';
  private accessToken = 'YOUR_PRISMIC_ACCESS_TOKEN';

  private prismicClient = Prismic.client(this.apiEndpoint);

  constructor() { }

  async fetchDocumentByType(documentType: string) {
    try {
      const response = await this.prismicClient.query(
        Prismic.Predicates.at('document.type', documentType)
      );

      return response.results[0];
    } catch (error) {
      console.error(`Error fetching ${documentType} from Prismic:`, error);
      throw error;
    }
  }
  async fetchDocumentByUID(uid: string) {
    try {
      const response = await this.prismicClient.getByUID('privacy_policy', uid, {});

      return response;
    } catch (error) {
      console.error(`Error fetching document with UID ${uid} from Prismic:`, error);
      throw error;
    }
  }
  async fetchArticlesByDate(pageSize: number = 12) {
    try {
      const response = await this.prismicClient.query(
        Prismic.Predicates.at('document.type', 'article'),
        { orderings: '[my.article.date desc]', pageSize: pageSize }
      );

      if (Array.isArray(response.results) && response.results.length > 0) {
        response.results.forEach(result => {
            const date = new Date(result.data.date);
            result.data.date_formatted = date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            });
        });
    }
    //console.log(response.results);
      return response.results;
    } catch (error) {
      console.error('Error fetching articles from Prismic:', error);
      throw error;
    }
  }
  async fetchArticlesForQuickLinks() {
    try {

      const response = await this.prismicClient.query(
        [
            Prismic.Predicates.at('document.type', 'article'),
            Prismic.Predicates.at('my.article.show_as_footer_quick_link', true)
        ],
        { fetch: ['article.uid', 'article.title'] }
    );
    
      return response.results;
    } catch (error) {
      console.error('Error fetching articles from Prismic:', error);
      throw error;
    }
  }
  async fetchArticlesByUID(uid: string) {
    try {
      const response = await this.prismicClient.query(
        Prismic.Predicates.at('my.article.uid', uid)
    );
      if (Array.isArray(response.results) && response.results.length > 0) {
        response.results.forEach(result => {
            const date = new Date(result.data.date);
            result.data.date_formatted = date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            });
        });
    }
      return response.results;
    } catch (error) {
      console.error('Error fetching articles from Prismic:', error);
      throw error;
    }
  }

}