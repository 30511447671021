<div id="blog" class="one-blog-area pb-70" style="padding-top:25px;">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title-blue">THE RESEARCH CORNER</span>
            <h2>Articles & Insights</h2>
        </div>

            <div class="row">
                <div class="col-sm-6 col-lg-4" *ngFor="let article of articles">
                    <div class="blog-item">
                        <div class="blog-top">
                            <a routerLink="/article/link/{{article.uid}}"><img src="{{article.data.thumbnail.url}}" alt="{{article.data.thumbnail.alt}}"></a>
                            <span>Research Article</span>
                        </div>
                        <div class="blog-bottom">
                            <h3><a routerLink="/article/link/{{article.uid}}">{{article.data.title}}</a></h3>
                            <span><i>Articles &amp; Insights</i></span>
                            <!--<img src="assets/img/home-one/author-pic.png" alt="Blog">
                            <span><i>{{ article.data.date_formatted }}</i></span>
                            <p><a routerLink="/">Thomas J. Knoll, Jr.</a>, Author</p>-->
                        </div>
                    </div>
                </div>
            </div>

    </div>
</div>
