<div class="banner-text">
    <div class="banner-form">
        <div class="banner-form-span">
            <span>Reverse Mortgage Calculator</span>
        </div>
        
        <form>
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group mb-3">
                    <mat-progress-bar 
                        class="mat-progress-bar"
                        mode="determinate"
                        [value]="progressValue">
                    </mat-progress-bar>
                </div>
                </div>

                <div class="col-lg-12" *ngIf="step === 1" @slideUpDown>
                    <div class="form-group mb-3">
                        <label>What is your Home Street Address?</label>
                        <input type="text" 
                                [(ngModel)]="homeAddress" 
                                name="homeAddress" 
                                class="form-control" 
                                placeholder="ex. 1234 Main St.">
                        <div *ngIf="homeAddressInvalid" class="text-danger">
                            Please enter a valid home address.
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" *ngIf="step === 1" @slideUpDown>
                    <div class="form-group mb-3">
                        <label>What is your Home Zip Code?</label>
                        <input type="text" 
                                [(ngModel)]="homeZipCode" 
                                name="homeZipCode"
                                pattern="\d{5}"
                                class="form-control" 
                                placeholder="5 digit zip code (California Only)">
                                <div *ngIf="zipCodeInvalid" class="text-danger">
                                    Please enter a valid Zip Code.
                                </div>
                            </div>
                </div>
                <div class="col-lg-12" *ngIf="step === 2" @slideUpDown>
                    <div class="form-group mb-3">
                        <label>How much is your home worth?</label>
                        <input type="text"
                                currencyMask
                                [options]="{ align: 'left', prefix: '$ ', thousands: ',', precision: 0 }"
                                [(ngModel)]="homeWorth"
                                name="homeWorth" 
                                class="form-control" 
                                placeholder="Enter the amount">

                                <div *ngIf="homeWorthInvalid" class="text-danger">
                                    Please enter a valid Dollar Amount.
                                </div>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="step === 2" @slideUpDown>
                    <div class="form-group mb-3">
                        <label>What is your total mortgage balance?</label>
                        <input type="text" 
                                currencyMask
                                [options]="{ align: 'left', prefix: '$ ', thousands: ',', precision: 0 }"
                                [(ngModel)]="mortgageBalance" 
                                name="mortgageBalance" 
                                class="form-control" 
                                placeholder="Enter the amount">

                                <div *ngIf="mortgageBalanceInvalid" class="text-danger">
                                    Please enter a valid Dollar Amount. Set to 0 if you don't have a mortgage.
                                </div>
                    </div>
                </div>

                <div class="col-lg-6" *ngIf="step === 3" @slideUpDown>
                    <div class="form-group mb-3">
                        <label>Your Age</label>
                        <select class="form-control" [(ngModel)]="yourAge" name="yourAge">
                            <!--<option>- select -</option>-->
                            <option *ngFor="let age of ages" [value]="age" [selected]="age === 62">{{ age }}</option>
                        </select>
                        <div *ngIf="ageInvalid" class="text-danger">
                            You or your spouse has to be age 62 or over
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" *ngIf="step === 3" @slideUpDown>
                    <div class="form-group mb-3">
                        <label>Your Spouses' Age</label>
                        <select class="form-control" [(ngModel)]="spouseAge" name="spouseAge">
                            <!--<option>- select -</option>-->
                            <option selected="selected">I'm Not Married</option>
                            <option *ngFor="let age of ages" [value]="age">{{ age }}</option>
                        </select>	
                    </div>
                </div>
                <!--
                <div class="col-lg-12" *ngIf="step === 4" @slideUpDown>
                    <h3>We just need some final information to show you your report</h3>
                </div>
                -->
                <div class="col-lg-12">  
                    <div class="form-group mb-3" *ngIf="step === 4" @slideUpDown>
                        <label>What is your First Name?</label>
                        <input type="text" 
                                [(ngModel)]="firstName" 
                                name="firstName" 
                                class="form-control" 
                                placeholder="Enter First Name here">
                        <div *ngIf="firstNameInvalid" class="text-danger">
                            Please enter your first name
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">  
                    <div class="form-group mb-3" *ngIf="step === 4" @slideUpDown>
                        <label>What is your Email Address?</label>
                        <input type="text" 
                                [(ngModel)]="email" 
                                name="email" 
                                class="form-control" 
                                placeholder="Enter email here">
                                
                                <div *ngIf="emailInvalid" class="text-danger">
                                    Please enter a valid email
                                </div>        
                    </div>
                </div>

                <div class="col-lg-12" *ngIf="step === 4" @slideUpDown>
                    <div class="form-group mb-3">
                        <label>What is your Phone Number</label>
                        <input type="text" 
                                [(ngModel)]="phone" 
                                name="phone" 
                                class="form-control" 
                                placeholder="Enter Phone Here">
                                <div *ngIf="phoneInvalid" class="text-danger">
                                    Please enter your phone number
                                </div>

                    </div>
                </div>
                <div class="col-lg-12" *ngIf="step === 5">
                    <div class="form-group mb-3" style="padding-top:12px;">
                        <div class="text-center mb-3" *ngIf="showReportSpinner" @slideUpDown>
                        Creating an estimate for {{ firstName }}<br><br>
                        <div class="spinner-border text-center" role="status">
                        </div>
                        </div>
                        <div class="text-center mb-3" *ngIf="!showReportSpinner" @slideUpDown>
                            <h3>Thank you, {{ firstName }}!</h3>
                            <h4>Your Estimate is complete</h4>
                            <div *ngIf="!isIneligible">
                                <h5>You could be elibible for</h5>
                                <h2>{{ estimatedAmount }}</h2>
                            </div>
                            <div *ngIf="isIneligible">
                                <h5>I'm sorry, but you are currently In-Eligible for a Reverse Mortgage</h5>
                            </div>
                            <!---
                            <b style="color:black">Based on your criteria</b>
                            <table style="margin-left:auto;margin-right:auto;color:black;">
                                <tr>
                                    <td style="text-align:right;">Address:</td>
                                    <td style="text-align:left;padding-left:10px;">{{ homeAddress }}</td>
                                </tr>   
                                <tr>
                                    <td style="text-align:right;">Zip Code:</td>
                                    <td style="text-align:left;padding-left:10px;">{{ homeZipCode }}</td>
                                </tr>
                                <tr>
                                    <td style="text-align:right;">Home Worth:</td>
                                    <td style="text-align:left;padding-left:10px;">{{ homeWorth | currency:'USD':true }}</td>
                                </tr>
                                <tr>
                                    <td style="text-align:right;">Mortgage Balance:</td>
                                    <td style="text-align:left;padding-left:10px;">{{ mortgageBalance | currency:'USD':true }}</td>
                                </tr>
                                <tr>
                                    <td style="text-align:right;">Your Age:</td>
                                    <td style="text-align:left;padding-left:10px;">{{ yourAge }}</td>
                                </tr>
                                <tr *ngIf="spouseAge !== 'I\'m Not Married'">
                                    <td style="text-align:right;">Spouse Age:</td>
                                    <td style="text-align:left;padding-left:10px;">{{ spouseAge }}</td>
                                </tr>                    
                            </table>
                            -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="step === 1">
                    <div class="form-group mb-3" style="padding-top:12px;">
                        <button (click)="nextButtonClick()" class="btn banner-form-btn">Next <i class='bx bx-right-arrow-alt'></i></button>
                    </div>
                </div>
                <div class="col-6" *ngIf="step === 2 || step === 3 || step === 4">
                    <div class="form-group mb-3" style="padding-top:12px;">
                        <button (click)="backButtonClick()" class="btn banner-form-btn"><i class='bx bx-left-arrow-alt'></i> Back</button>
                    </div>
                </div>
                <div class="col-6" *ngIf="step === 2 || step === 3">
                    <div class="form-group mb-3" style="padding-top:12px;">
                        <button (click)="nextButtonClick()" class="btn banner-form-btn">Next <i class='bx bx-right-arrow-alt'></i></button>
                    </div>
                </div>
                <div class="col-6" *ngIf="step === 4">
                    <div class="form-group mb-3" style="padding-top:12px;">
                        <button (click)="nextButtonClick()" class="btn banner-form-btn">Show Report <i class='bx bx-right-arrow-alt'></i></button>
                    </div>
                </div>
                <div class="col-lg-12 text-center" *ngIf="step === 5 && showReportSpinner == false">
                    <button onclick="window.open('https://form.jotform.com/233384721305149', '_blank', 'width=500,height=500')" class="btn banner-form-btn" style="width:150px;"><i class='bx bx-link-external'></i>Apply Now</button>
                    <br><br><a (click)="startOverClick()" style="cursor:pointer;text-align:center;">Start Over</a>
                </div>
            </div>
        </form>
    </div>
</div>