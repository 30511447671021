<div class="one-blog-area pb-70" style="margin-top:25px;">
    <div class="container">
        <div class="one-section-title">
            <h1 *ngIf="prismic">{{prismic.title[0].text}}</h1>
        </div>

        <div *ngIf="prismic" class="row" [innerHTML]="prismic.content_html">
        
        </div>
    </div>
</div>


