import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrismicService } from '../../services/prismic.service';
import * as prismicClient from '@prismicio/client'
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss']
})
export class ArticleDetailsComponent {
  prismic: any;

  constructor(
    private route: ActivatedRoute,
    private prismicService: PrismicService,
    private titleService: Title,
    private metaService: Meta
    ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.link(params['id']);
      }
    });
  }
  link(id: string) {
    // First get the product id from the current route.
    
    this.prismicService.fetchArticlesByUID(id).then((documents) => {

      this.prismic = documents[0].data;
      this.prismic.article_content_html = prismicClient.asHTML(this.prismic.content);

      this.titleService.setTitle(this.prismic.title);
    
      this.metaService.updateTag(
        { name: 'description', content: this.prismic.meta_description }
      );
      
      this.metaService.updateTag(
        { name: 'keywords', content: this.prismic.meta_keywords }
      );
  
    }) .catch((error) => {  
      console.error('Error fetching Articles:', error);
    } );
  }
}
