import { Component } from '@angular/core';
import { PrismicService } from '../../services/prismic.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})

export class ArticlesComponent {
  articles: any;
  
  prismic: any;

  constructor(
    private prismicService: PrismicService,
    private titleService: Title,
    private metaService: Meta
    ) { }

  ngOnInit(): void {
      this.titleService.setTitle("Reverse Mortgage Research Corner");
    
      this.metaService.updateTag(
        { name: 'description', content: "Explore a comprehensive collection of reverse mortgage articles to research and gain insights into various aspects of reverse mortgages. Discover information on the benefits, risks, retirement planning, home equity, financial strategies, and options available for seniors. Make informed decisions about reverse mortgages for a secure and comfortable retirement." }
      );
    
      this.metaService.updateTag(
        { name: 'keywords', content: "Reverse Mortgage Articles, Reverse Mortgage Research, Reverse Mortgage Information, Reverse Mortgage Benefits, Reverse Mortgage Risks, Retirement Planning, Home Equity, Financial Insights, Senior Finances, Reverse Mortgage Options" }
      );
      
      this.prismicService.fetchArticlesByDate().then((articles) => {
      this.articles = articles;

    }) .catch((error) => {  
      console.error('Error fetching Articles:', error);
    } );
  }
}
