<div class="one-blog-area pb-70" style="margin-top:25px;">
    <div class="container">
        <div class="one-section-title">
            <h1>Thank you for your Application</h1>
        </div>

        <div class="row text-center">
            <h4>A representative will be contacting you shortly</h4>
        </div>
    </div>
</div>

