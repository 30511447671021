import { Component } from '@angular/core';
import { PrismicService } from '../../services/prismic.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {

  prismic: any;

  constructor(private prismicService: PrismicService) { }

  ngOnInit(): void {
        this.prismicService.fetchDocumentByType('header').then((documents) => {
         this.prismic = documents.data;
        } ).catch((error) => {
          console.error('Error fetching Home Page:', error);
       });
  }
}
