import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormSubmitServiceService {

  //apiURL = 'http://localhost:3000/api/submit';
  apiURL = 'https://api.knollfinancial.com/api/submit';

  constructor(private http: HttpClient) { }

  // Add a function to make a POST request
  submitForm(data: any, endpoint: string): Observable<any> {
    const url = this.apiURL + '/' + endpoint;

    // You can adjust the headers and options as needed
    const headers = { 'Content-Type': 'application/json' };

    return this.http.post(url, data, { headers });
  }
  submitContactForm(data: any): Observable<any> {
    //const url = this.apiURL + '/contact';
    const url = 'https://zlzou7crf5.execute-api.us-east-1.amazonaws.com/default/submitContactForm';
    // You can adjust the headers and options as needed
    const headers = { 'Content-Type': 'application/json' };

    return this.http.post(url, data, { headers });
  }
  submitCalculatorForm(data: any): Observable<any> {
    //const url = this.apiURL + '/calculator';
    const url = 'https://rzff7qmwg5.execute-api.us-east-1.amazonaws.com/default/submitCalculatorForm';
    // You can adjust the headers and options as needed
    const headers = { 'Content-Type': 'application/json' };

    return this.http.post(url, data, { headers });
  }
}
