import { Component } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-new-app-thank-you',
  templateUrl: './new-app-thank-you.component.html',
  styleUrls: ['./new-app-thank-you.component.scss']
})
// this page is meant to only trigger the thank you page for mortgage application
export class NewAppThankYouComponent {

  constructor(
    private analyticsService: AnalyticsService
    ) { }

  ngOnInit(): void {
    // Track the event with GA4
    this.analyticsService.event('submit_form', {
      'event_category': 'Form',
      'event_label': 'Mortgage Application'
    });

  }
}
