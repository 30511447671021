import { Component, OnInit } from '@angular/core';
import { PrismicService } from '../../services/prismic.service';
import { FormSubmitServiceService } from '../../services/form-submit-service.service';
import * as prismicClient from '@prismicio/client'
import { Title, Meta } from '@angular/platform-browser';
import { AnalyticsService } from '../../services/analytics.service';
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  articles: any;

  prismic: any;

  // contact form fields
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: string = '';
  message: string = '';

  firstNameInvalid: boolean = false;
  lastNameInvalid: boolean = false;
  emailInvalid: boolean = false;
  phoneInvalid: boolean = false;
  messageInvalid: boolean = false;  

  formSubmitSuccess: boolean = false;

  constructor(
    private prismicService: PrismicService,
    private formSubmitService: FormSubmitServiceService,
    private titleService: Title,
    private metaService: Meta,
    private analyticsService: AnalyticsService
    ) { }

  ngOnInit(): void {

    this.prismicService.fetchDocumentByType('home_page').then((documents) => {
      this.prismic = documents.data;

      this.titleService.setTitle(this.prismic.meta_title);
    
      this.metaService.updateTag(
        { name: 'description', content: this.prismic.meta_description }
      );
      
      this.metaService.updateTag(
        { name: 'keywords', content: this.prismic.meta_keywords }
      );
        
      this.prismicService.fetchArticlesByDate(3).then((articles) => {
        this.articles = articles;

        $.getScript('../assets/js/custom.js');
      }) .catch((error) => {  
        console.error('Error fetching Articles:', error);
      } );

    } ).catch((error) => {
      console.error('Error fetching Home Page:', error);
    });
  }
  
  TextAsHTML(data:any):any {
    return prismicClient.asHTML(data);
  }
  range(start: number, end: number): number[] {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }
  stripPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/\D/g, '');
  }
  submitContactForm() {
    if ( this.validateContactForm() ) {
      return;
    }
    this.formSubmitSuccess = true;

    const data = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.stripPhoneNumber(this.phone),
      message: this.message
    };
    // Track the event with GA4
    this.analyticsService.event('submit_form', {
      'event_category': 'Form',
      'event_label': 'Contact Us'
    });
    this.formSubmitService.submitContactForm(data).subscribe(response => {
      //console.log(response);
      //console.log('Form Submitted Successfully');
    });
  }
  validateContactForm(): boolean {
    this.firstNameInvalid = ( this.firstName == '' );
    this.lastNameInvalid = ( this.lastName == '' );
    this.emailInvalid = (this.email == '' || !this.validEmail(this.email));
    this.phoneInvalid = ( this.phone == '' );
    this.messageInvalid = ( this.message == '' );

    return ( this.firstNameInvalid || this.lastNameInvalid || this.emailInvalid || this.phoneInvalid || this.messageInvalid );
  }
  validEmail(email) {
    const regex = /\S+@\S+\.\S+/;

    return regex.test(email);
  }
}
