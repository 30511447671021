<div id="blog" class="one-blog-area pb-70" style="padding-top:25px;">
    <div class="container">
        <div class="one-section-title" *ngIf="prismic">
            <a routerLink="/articles">Back to All Articles</a>
            <h2>{{ prismic.title }}</h2>
            <!--<p>{{ prismic.date_formatted }}</p>-->
        </div>

        <div class="row" *ngIf="prismic">
            <div *ngIf="prismic" [innerHTML]="prismic.article_content_html">
        
            </div>
            <div style="text-align:center"><a routerLink="/articles">Back to All Articles</a></div>
        </div>
    </div>
</div>
