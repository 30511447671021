import { Component } from '@angular/core';
import { PrismicService } from '../../services/prismic.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  prismic: any;

  quickLinks: any;

  date = new Date();
  currentYear = this.date.getFullYear();

  constructor(private prismicService: PrismicService) { }
  
  stripPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/\D/g, '');
  }
  ngOnInit(): void {
    this.prismicService.fetchArticlesForQuickLinks().then((links) => {
      this.quickLinks = links;
     } ).catch((error) => {
       console.error('Error fetching Home Page:', error);
    });

    this.prismicService.fetchDocumentByType('footer').then((documents) => {
         this.prismic = documents.data;
        } ).catch((error) => {
          console.error('Error fetching Home Page:', error);
       });
  }

}
