import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { FormSubmitServiceService } from '../../services/form-submit-service.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  animations: [
    trigger('fadeInOut', [
      // Fade in
      transition('void => *', [
        style({ opacity: 0 }), // start with opacity 0
        animate('500ms', style({ opacity: 1 })) // animate to opacity 1
      ]),
      // Fade out
      transition('* => void', [
        animate('500ms', style({ opacity: 0 })) // animate to opacity 0
      ])
    ]),
    trigger('slideUpDown', [
      transition('void => *', [
        style({ height: '0', overflow: 'hidden' }), // Start with a height of 0
        animate('500ms ease-in', style({ height: '*' })) // Animate to the element's natural height
      ]),
      transition('* => void', [
        style({ height: '*', overflow: 'hidden' }), // Start with the element's natural height
        animate('500ms ease-out', style({ height: '0' })) // Animate to a height of 0
      ])
    ])
  ]
})
export class CalculatorComponent {

  // form fields
  homeAddress: string = '';
  homeZipCode: string = '';
  homeWorth: string = '100000';
  mortgageBalance: string = '0';
  yourAge: string = '62';
  spouseAge: string = "I'm Not Married";
  firstName: string = '';
  email: string = '';
  phone: string = '';
  estimatedAmount: string = '';
  progressValue = 20;

  homeAddressInvalid = false;
  zipCodeInvalid = false;
  homeWorthInvalid = false;
  mortgageBalanceInvalid = false;
  ageInvalid = false;
  firstNameInvalid = false;
  emailInvalid = false;
  phoneInvalid = false;

  plfData: { age: number, plf: number }[] = []

  // 3 steps. 
  step: number = 1;

  ages = Array.from({length: 82}, (v, k) => k + 18);
  
  showReportSpinner = false;

  isIneligible = false;

  constructor(
    private http: HttpClient,
    private formSubmitService: FormSubmitServiceService,
    private analyticsService: AnalyticsService
    ) { 
    this.loadPLFcsv()
  }

  submitCalculatorForm() {
    
    const data = {
      homeAddress: this.homeAddress,
      homeZipCode: this.homeZipCode,
      homeWorth: this.formatNumberAsCurrency(parseInt(this.homeWorth)),
      mortgageBalance: this.formatNumberAsCurrency(parseInt(this.mortgageBalance)),
      yourAge: this.yourAge,
      spouseAge: this.spouseAge,
      firstName: this.firstName,
      email: this.email,
      phone: this.phone,
      estimatedAmount: this.estimatedAmount
    };
    // Track the event with GA4
    this.analyticsService.event('submit_form', {
      'event_category': 'Form',
      'event_label': 'Reverse Mortgage Calculator'
    });

    this.formSubmitService.submitCalculatorForm(data).subscribe(response => {
      //console.log(response);
    });
  }
  formatNumberAsCurrency(num: number): string {
    return new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD', 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0 
    }).format(num).replace(/\D00(?=\D*$)/, '');
}
  nextButtonClick() {
    if ( this.step == 1 ) {
      this.homeAddressInvalid = this.homeAddress == '';

      const zipCodePattern = /^\d{5}$/;
      this.zipCodeInvalid = !zipCodePattern.test(this.homeZipCode);

      if ( this.homeAddressInvalid == true || this.zipCodeInvalid == true ) {
        return;
      }
    } else if ( this.step == 2 ) {
      //console.log('homeWorth: ' + this.homeWorth);
      //console.log('mortgageBalance: ' + this.mortgageBalance);

      this.homeWorthInvalid = (this.homeWorth == null || this.homeWorth == '0'); 
      this.mortgageBalanceInvalid = (this.mortgageBalance == null); 
      //console.log(this.mortgageBalanceInvalid);
      if ( this.homeWorthInvalid == true || this.mortgageBalanceInvalid == true ) {
        return;
      }

    } else if ( this.step == 3 ) {
      const yourAge:number = this.extractNumbers(this.yourAge);
      const spouseAge:number = (this.spouseAge == "I'm Not Married") ? 0 : this.extractNumbers(this.spouseAge);
      
      this.ageInvalid = ( yourAge < 62 && spouseAge < 62 );

      if ( this.ageInvalid ) {
        return;
      }
    } else if (this.step == 4 ) {
      this.firstNameInvalid = (this.firstName == '');
      this.emailInvalid = (this.email == '' || !this.validEmail(this.email));
      this.phoneInvalid = (this.phone == '');
      
      if ( this.firstNameInvalid || this.emailInvalid || this.phoneInvalid ) {
        return;
      }

      // this will show the final report
      this.showReportSpinner = true;

      setTimeout(() => {
        this.showReportSpinner = false;
      }, 2500);

      const estimation = this.setEstimatedAmount();

      // show the user the estimated amount or tell them they are ineligible
      if ( estimation > 0 ) { 
        this.estimatedAmount = this.formatNumberAsCurrency(estimation);
        this.isIneligible = false;
      } else {
        this.isIneligible = true;
      }
      

      this.submitCalculatorForm();
    }

    this.step++;
    this.setProgress();
  }

  setEstimatedAmount() {
    const nHomeWorth:number = this.extractNumbers(this.homeWorth);
    const nMortgageBalance:number = this.extractNumbers(this.mortgageBalance);
    const nYourAge:number = this.extractNumbers(this.yourAge);
    const nSpouseAge:number = (this.spouseAge == "I'm Not Married") ? 0 : this.extractNumbers(this.spouseAge);

    // we want the oldest age
    const age = (nYourAge > nSpouseAge) ? nYourAge : nSpouseAge;

    const plf = this.plfData.find(item => item.age == age).plf;
/*
    console.log('nHomeWorth: ' + nHomeWorth);
    console.log('nMortgageBalance: ' + nMortgageBalance);
    console.log('age: ' + age);
    console.log('plf: ' + plf);
*/
    const principalLimit = nHomeWorth * plf;
//    console.log('principalLimit: ' + principalLimit);


    const estimatedAmount = principalLimit - (nHomeWorth * 0.03) - nMortgageBalance;

    return estimatedAmount;
  }
  backButtonClick() {
    this.step--;
    this.setProgress();
  }
  setProgress() {
    this.progressValue = (20*this.step);
  }
  startOverClick() {
    this.step = 1;
    this.setProgress();
    
    //this.resetForm();
  }
  resetForm() {
    this.homeAddress = ''; 
    this.homeZipCode = '';
    this.homeWorth = '';
    this.mortgageBalance = '';
    this.yourAge = '62';
    this.spouseAge = "I'm Not Married";
    this.email = '';
    this.phone = '';  

    this.homeAddressInvalid = false;
    this.zipCodeInvalid = false;
    this.homeWorthInvalid = false;
    this.mortgageBalanceInvalid = false;
    this.ageInvalid = false;
    this.firstNameInvalid = false;
    this.emailInvalid = false;
    this.phoneInvalid = false;
    this.isIneligible = false;

  }
  loadPLFcsv() {
    this.http.get('assets/plf.csv', { responseType: 'text' })
      .subscribe(data => {
        const csvData = data.split('\n');
        csvData.forEach(row => {
          const [age, plf] = row.split(',');
          if (age && plf) {
            this.plfData.push({ age: parseInt(age), plf: parseFloat(plf) });
          }
      });
    });
  }
  extractNumbers(input) {
    if (typeof input !== 'string') {
      return input.toString().replace(/\D/g, '');
    }
    return input.replace(/\D/g, '');
  }
  validEmail(email) {
    const regex = /\S+@\S+\.\S+/;

    return regex.test(email);
  }
}
/*
	jQuery(".open4").click(function() {
      if (v.form()) {
      	jQuery('#open4').prop('disabled', true);
		save_calculator();
        jQuery("#loader").show();

              var PLF = {
                  "62" : 0.524,
                  "63" : 0.53,
                  "64" : 0.536,
                  "65" : 0.542,
                  "66" : 0.549,
                  "67" : 0.556,
                  "68" : 0.562,
                  "69" : 0.569,
                  "70" : 0.576,
                  "71" : 0.578,
                  "72" : 0.58,
                  "73" : 0.587,
                  "74" : 0.593,
                  "75" : 0.601,
                  "76" : 0.606,
                  "77" : 0.613,
                  "78" : 0.621,
                  "79" : 0.626,
                  "80" : 0.634,
                  "81" : 0.643,
                  "82" : 0.651,
                  "83" : 0.66,
                  "84" : 0.669,
                  "85" : 0.678,
                  "86" : 0.688,
                  "87" : 0.697,
                  "88" : 0.705,
                  "89" : 0.715,
                  "90" : 0.725,
                  "91" : 0.736,
                  "92" : 0.746,
                  "93" : 0.75,
                  "94" : 0.75,
                  "95" : 0.75,
                  "96" : 0.75,
                  "97" : 0.75,
                  "98" : 0.75,
                  "99" : 0.75
               };    
               var home_Value = jQuery('#home_value').val().replace(/,/g, '');
               var mortgage_balance = jQuery('#mortgage_balance').val().replace(/,/g, '');
               var age;
               	if(jQuery('#spouse').val() == 'yes'){
        			var birth_month = jQuery('#sage_month').val();
        			var birth_day = jQuery('#sage_date').val();
        			var birth_year = jQuery('#sage_year').val();
                    today_date = new Date();
        			today_year = today_date.getFullYear();
        			today_month = today_date.getMonth();
        			today_day = today_date.getDate();
        			age = today_year - birth_year;
        		
        			if ( today_month < (birth_month - 1))
        			{
        				age--;
        			}
        			if (((birth_month - 1) == today_month) && (today_day < birth_day))
        			{
        				age--;
        			}
               	} else{ 
                    var birth_month = jQuery('#age_month').val();
        			var birth_day = jQuery('#age_date').val();
        			var birth_year = jQuery('#age_year').val();
                    today_date = new Date();
        			today_year = today_date.getFullYear();
        			today_month = today_date.getMonth();
        			today_day = today_date.getDate();
        			age = today_year - birth_year;
        		
        			if ( today_month < (birth_month - 1))
        			{
        				age--;
        			}
        			if (((birth_month - 1) == today_month) && (today_day < birth_day))
        			{
        				age--;
        			}
               	}
               
               if(age>=62){
                   if(age > 99){
                       age = 99;
                   }
                   var plf_val = PLF[age];
                   var principal_limit = home_Value * plf_val;
                   var cash = principal_limit - (home_Value*0.03) - mortgage_balance;
                   jQuery('#cash').val(cash);
                   if(cash > 0){
                      jQuery("h3.card-title").text("Congratulations!");
                      jQuery("p.card-text").html("You appear to be eligible to receive <strong>$"+ ReplaceNumberWithCommas(cash.toFixed(0)) +"</strong>");
                   } else {
                       jQuery("h3.card-title").text("Thank You.");
                       jQuery("p.card-text").text("A Representative will contact you regarding your options.");
                   }
               } else {
                  jQuery("h3.card-title").text("Thank You.");
                  jQuery("p.card-text").text("A Representative will contact you regarding your options.");
               }
                //var form_value = jQuery("#basicform").serialize()+'&cash='+cash; alert(form_value);
               jQuery('div.card-header').addClass('final');
               jQuery('div.card-header .header-img').show('fast');
               jQuery(".frm").hide("fast");
               jQuery("#sf4").show("slow");
               jQuery('html, body').animate({
                    scrollTop: jQuery('.header-text').offset().top
                }, 100);
            jQuery.ajax({
	            url: base_url + 'upload_leads',
	            type: 'post',
	            data:  jQuery("#basicform").serialize()+'&cash='+jQuery('#cash').val(),
	            success: function (result) {  
	            	if(result == 'SUCCESS'){
	            		fbq('track', 'Lead');
	            	}
	            }
	        });
		return false;
      }
    });

  });
  */