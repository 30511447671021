import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ArticleDetailsComponent } from './components/article-details/article-details.component';
import { AccessibilityComponent } from './components/accessibility/accessibility.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { LicensingComponent } from './components/licensing/licensing.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NewAppThankYouComponent } from './components/new-app-thank-you/new-app-thank-you.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'home', component: HomeComponent},
    {path: 'articles', component: ArticlesComponent},
    {path: 'article/:uid', component: ArticleDetailsComponent },
    { 
        path: 'article', 
        children: [
          {
            path: 'link/:id',
            component: ArticleDetailsComponent
          },
        ]
      },
    {path: 'accessibility', component: AccessibilityComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'licensing', component: LicensingComponent},
    {path: 'mortgage-application-submit-thank-you', component: NewAppThankYouComponent},
    {path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }