<section class="one-subscribe-area">
    <div class="subscribe-wrap" *ngIf="prismic">
        <i class='bx {{ prismic.bottom_cta_box_icon }}'></i>
        <h2>{{ prismic.bottom_cta_main_title }}</h2>
        <h3>{{ prismic.bottom_cta_secondary_title }}</h3>

        <form class="newsletter-form">
            <button class="btn subscribe-btn" onclick="location.href = '#contact'">Contact Us</button>
        </form>
        
    </div>
</section>

<footer class="one-footer-area pt-100">
<div class="container">
    <div class="klaviyo-form-U6XRqf"></div>
    <div class="row">
        <div class="col-sm-6 col-lg-3">
            <div class="footer-item">
                <div class="footer-logo" *ngIf="prismic">
                    <a routerLink="/"><img src="{{prismic.logo.url}}" alt="{{prismic.logo.alt}}"></a>
                    <p [innerHTML]="prismic.text_under_logo[0].text"></p>
                    <ul>
                        <li><a href="{{prismic.facebook.url}}" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="{{prismic.instagram.url}}" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="{{prismic.linked_in.url}}" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3">
            <div class="footer-item">
                <div class="footer-quick">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a href="#calculator">Reverse Mortgage Calculator</a></li>
                        <li *ngFor="let link of quickLinks"><a routerLink="/article/link/{{link.uid}}">{{link.data.title}}</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3">
            <div class="footer-item">
                <div class="footer-quick">
                    <h3>About Us</h3>

                    <ul>
                        <li><a href="#about">The Knoll Difference</a></li>
                        <li><a href="#contact">Contact</a></li>
                        <li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li><a routerLink="/accessibility">Accessibility Statement</a></li>
                        <li><a routerLink="/licensing">Licensing & Disclosures</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3">
            <div class="footer-item">
                <div class="footer-address">
                    <h3>Address</h3>

                    <ul *ngIf="prismic">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:{{stripPhoneNumber(prismic.phone[0].text)}}">{{prismic.phone[0].text}}</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:{{prismic.email[0].text}}">{{prismic.email[0].text}}</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location'></i>
                            {{prismic.address[0].text}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="copyright-item">
                    <p>KnollFinancial.com - © {{currentYear}} All Rights Reserved</p>
                </div>
            </div>
        </div>
    </div>
</div>
</footer>