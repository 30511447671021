<div class="one-banner-area">
    <div class="banner-shape">
        <img src="assets/img/home-one/banner-shape4.png" alt="Banner">
        <img src="assets/img/home-one/banner-shape3.png" alt="Banner">
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="banner-text" *ngIf="prismic">
                    <!--
                    <div class="banner-video">
                        <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play' ></i></a>
                        <span>Into With Compnay</span>
                    </div>
                    -->
                    <h1>{{ prismic.hero_main_title }}</h1>
                    <p>{{ prismic.hero_sub_title }}</p>
                </div>
            </div>

            <div class="col-lg-6">
                <!-- calculator here -->
                <app-calculator></app-calculator>
            </div>
        </div>
    </div>
</div>

<section id="pledge" class="one-service-area ptb-100 pb-70">
    <div class="container" *ngIf="prismic">
        <div class="one-section-title">
            <span class="sub-title-blue">{{ prismic.pledge_small_title }}</span>
            <h2>{{ prismic.pledge_big_title }}</h2>
            <p>{{ prismic.pledge_description }}</p>
        </div>

        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="nav-item nav-default" id="nav-{{i}}-tab" data-bs-toggle="tab" href="#nav-{{i}}" role="tab" aria-controls="nav-home" aria-selected="true" *ngFor="let item of prismic.pledge_items; let i = index" [class.active]="i === 0">
                    <div class="service-item">
                        <i class='bx {{ item.box_icon_css }}'></i>
                        <span>{{ item.title }}</span>
                    </div>
                </a>
            </div>
        </nav>
            
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-{{i}}" role="tabpanel"  *ngFor="let item of prismic.pledge_items; let i = index" [ngClass]="{ 'active': i === 0, 'show': i === 0 }">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="{{ item.image.url }}" alt="{{ item.image.alt }}">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.description }}</p>
                                <ul>
                                    <li *ngIf="item.bullet_point_1"><i class='bx bxs-chevrons-right'></i> {{ item.bullet_point_1 }}</li>
                                    <li *ngIf="item.bullet_point_2"><i class='bx bxs-chevrons-right'></i> {{ item.bullet_point_2 }}</li>
                                    <li *ngIf="item.bullet_point_3"><i class='bx bxs-chevrons-right'></i> {{ item.bullet_point_3 }}</li>
                                    <li *ngIf="item.bullet_point_4"><i class='bx bxs-chevrons-right'></i> {{ item.bullet_point_4 }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="one-help-area ptb-100" id="benefits">
    <div class="help-img">
        <img src="assets/img/home-one/home-equity.jpg" alt="Help">
    </div>

    <div class="container">
        <div class="help-item" *ngIf="prismic">
            <div class="one-section-title">
                <span class="sub-title">{{ prismic.benefits_small_title }}</span>
                <h2>{{ prismic.benefits_big_title }}</h2>
            </div>
            <ul>
                <li *ngFor="let benefit of prismic.benefits">
                    <div class="help-icon">
                        <i class='bx {{ benefit.box_icon }}'></i>
                    </div>
                    <div class="help-inner">
                        <h3>{{ benefit.title }}</h3>
                        <p>{{ benefit.description }}</p>
                    </div>
                </li>
            </ul>
            <div class="advisor-link">
                <a href="javascript:void( window.open( 'https://form.jotform.com/233384721305149', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) )">GET STARTED</a>
                <i class="bx bx-right-arrow-alt"></i>
            </div>
        </div>
    </div>
</section>

<div id="about" class="one-about-area">
    <div class="container-fluid p-0">
        <div class="row m-0" *ngIf="prismic">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="{{ prismic.about_main_image.url }}" alt="{{ prismic.about_main_image.alt }}">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title">
                        <span class="sub-title-blue">{{ prismic.about_small_title }}</span>
                        <h2>{{ prismic.about_big_title }}</h2>
                        <p>{{ prismic.about_description }}</p>
                    </div>

                    <div class="about-tablist">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation" *ngFor="let tab of prismic.about_tabs; let i = index;">
                                <a class="nav-default" id="pills-{{ i }}-tab" data-bs-toggle="pill" href="#pills-{{ i }}" role="tab" [ngClass]="{ 'active': i === 0 }">
                                    <i class='bx {{ tab.box_icon }}' ></i>
                                    {{ tab.name }}
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade" id="pills-{{ i }}" role="tabpanel" *ngFor="let tab of prismic.about_tabs; let i = index;" [ngClass]="{ 'active': i === 0, 'show': i === 0 }">
                                <p>{{ tab.description }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="about-best">
                        <ul>
                            <li><h4>{{ prismic.about_serving_since }}</h4></li>
                            <li>
                                <img src="{{ prismic.about_signature_image.url }}" alt="{{ prismic.about_signature_image.alt }}">
                                <div class="sig-inner">
                                    <span>{{ prismic.about_name }}</span>
                                    <p>{{ prismic.about_title }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="team" class="one-team-area ptb-100">
    <div class="container">
        <div class="one-section-title"  *ngIf="prismic">
            <span class="sub-title-blue">{{ prismic.team_small_title[0].text }}</span>
            <h2>{{ prismic.team_big_title[0].text }}</h2>
            <!--<a routerLink="/"><i class='bx bx-plus'></i> JOIN TEAM</a>-->
        </div>

        <div class="row" *ngIf="prismic">
            <div class="col-sm-6 col-lg-3" *ngFor="let team of prismic.team_members">
                <div class="team-item">
                    <div class="team-img">
                        <img src="{{ team.team_pic.url }}" width="{{ team.team_pic.dimensions.width }}" height="{{ team.team_pic.dimensions.height }}" alt="{{ team.team_pic.alt }}">
                    </div>
                    <ul><li></li></ul>
                    <!--
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                    -->
                    <h3>{{ team.team_name[0].text }}</h3>
                    <span>{{ team.team_title[0].text }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="one-quote-area">
    <div class="container">
        <div class="row m-0" *ngIf="prismic">
            <div class="col-lg-6 p-0">
                <div class="quote-content">
                    <h3>{{ prismic.cta_title[0].text }}</h3>
                    <p>{{ prismic.cta_description[0].text }}</p>
                    <a href="#contact"><i class='bx bx-envelope'></i> Contact Us</a><br>
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="quote-img">
                    <img src="{{ prismic.cta_image.url }}" alt="{{ prismic.cta_image.alt }}">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="one-faq-area ptb-100" id="faq">
    <div class="container" *ngIf="prismic">
        <div class="one-section-title">
            <span class="sub-title-blue">{{prismic.faq_small_title[0].text}}</span>
            <h2>{{prismic.faq_big_title[0].text}}</h2>
        </div>
        <div class="faq-content">
            <ul class="accordion">
                
                <li *ngFor="let faqItem of prismic.faq">
                    <a>{{ faqItem.question[0].text }}</a>
                    <p>{{ faqItem.answer_ }}</p>
                </li>
            </ul>
            
        </div>
        
    </div>
</div>

<section class="one-testimonial-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title" *ngIf="prismic">
            <span class="sub-title-blue">{{ prismic.testimonial_small_title }}</span>
            <h2>{{ prismic.testimonial_big_title }}</h2>
        </div>

        <div class="row" *ngIf="prismic">
            <div class="col-sm-6 col-lg-4" *ngFor="let testimonial of prismic.testimonials">
                <div class="testimonial-item">
                    <div class="testimonial-top">
                        <h3>{{ testimonial.name }}</h3>
                        <ul>
                            <li *ngFor="let number of range(1, testimonial.stars)"><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <span>{{ testimonial.subject }}</span>
                    </div>

                    <div class="testimonial-bottom">
                        <p>{{ testimonial.message }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="contact" class="one-contact-area">
    <div class="container-fluid p-0">
        <div class="row m-0" *ngIf="prismic">
            <div class="col-lg-5 p-0">
                <div class="contact-img">
                    <img src="{{ prismic.contact_image.url }}" alt="{{ prismic.contact_image.alt }}">
                </div>
            </div>
            
            <div class="col-lg-7 p-0">
                <div class="contact-content">
                    <div class="one-section-title">
                        <span class="sub-title">{{ prismic.contact_small_title }}</span>
                        <h2>{{ prismic.contact_big_title }}</h2>
                    </div>
                    <div class="row m-0" *ngIf="formSubmitSuccess">
                        <div class="col-sm-6 col-lg-6">
                            <h3 style="color:#ffffff">Thank you for your submission. We will be in contact with you shortly.</h3>
                        </div>
                    </div>
                    <form id="contactForm" *ngIf="!formSubmitSuccess">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label><i class='bx bx-user'></i></label>
                                    <input type="text" name="first_name" [(ngModel)]="firstName" [class.is-invalid]="firstNameInvalid" id="first_name" class="form-control" required placeholder="First Name">
                                    <div *ngIf="firstNameInvalid" class="text-danger">
                                        Enter a First Name
                                    </div>                                
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label><i class='bx bx-user'></i></label>
                                    <input type="text" name="last_name" id="last_name" [(ngModel)]="lastName" [class.is-invalid]="lastNameInvalid"  class="form-control" required placeholder="Last Name">
                                    <div *ngIf="lastNameInvalid" class="text-danger">
                                        Enter a Last Name
                                    </div>                                
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label><i class='bx bx-mail-send'></i></label>
                                    <input type="email" name="email" id="email" [(ngModel)]="email" [class.is-invalid]="emailInvalid" class="form-control" required placeholder="Email">
                                    <div *ngIf="emailInvalid" class="text-danger">
                                        Enter a valid Email
                                    </div>                                
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label><i class='bx bx-phone-call'></i></label>
                                    <input type="text" name="phone_number" [(ngModel)]="phone"  id="phone_number" [class.is-invalid]="phoneInvalid" required class="form-control" placeholder="Phone">
                                    <div *ngIf="phoneInvalid" class="text-danger">
                                        Enter a valid Phone
                                    </div>                                
                                </div>
                            </div>
    
                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label><i class='bx bx-message-square-detail'></i></label>
                                    <textarea name="message" [(ngModel)]="message"  class="form-control" id="message" [class.is-invalid]="messageInvalid" cols="30" rows="8" required placeholder="Message"></textarea>
                                    <div *ngIf="messageInvalid" class="text-danger">
                                        Enter a Message
                                    </div>                                
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="row m-0">
                                    <div class="col-sm-4 col-lg-4 pl-0">
                                        <button (click)="submitContactForm()" class="contact-btn btn"><i class='bx bx-plus'></i> Send Message</button>
                                    </div>

                                    <div class="col-sm-8 col-lg-8">
                                        <div class="contact-emergency">
                                            <i class='bx bx-phone-call'></i>
                                            <span>Give us a ring</span>
                                            <a href="tel:{{stripPhoneNumber(prismic.contact_phone_number)}}">{{ prismic.contact_phone_number }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="articles" class="one-blog-area pt-100 pb-70">
    <div class="container" *ngIf="prismic">
        <div class="one-section-title">
            <span class="sub-title-blue">{{ prismic.articles_small_title }}</span>
            <h2>{{ prismic.articles_big_title }}</h2>
            <a routerLink="/articles">View All Articles</a>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let article of articles">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/article/link/{{article.uid}}"><img src="{{article.data.thumbnail.url}}" alt="{{article.data.thumbnail.alt}}"></a>
                        <span>Research Article</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/article/link/{{article.uid}}">{{article.data.title}}</a></h3>
                        <span><i>Articles &amp; Insights</i></span>
                        <!--
                            <span><i>{{ article.data.date_formatted }}</i></span>
                        
                        <img src="assets/img/home-one/author-pic.png" alt="Blog">
                        
                        <p><a routerLink="/">Thomas J. Knoll, Jr.</a>, Author</p>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
