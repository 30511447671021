import { PrismicService } from '../../services/prismic.service';
import * as prismicClient from '@prismicio/client'
import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  prismic: any;

  constructor(
    private prismicService: PrismicService) { }

  ngOnInit(): void {
    this.prismicService.fetchDocumentByUID('privacy-policy').then((documents) => {
      
      this.prismic = documents.data;
      this.prismic.content_html = prismicClient.asHTML(this.prismic.content);
     
    } ).catch((error) => {
      console.error('Error fetching Home Page:', error);
   });
  }

}
