<div class="one-header-top">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3">
                <div class="header-social">
                    <ul>
                        <li><a target="_blank" href="{{ prismic.facebook.url }}" *ngIf="prismic"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="{{ prismic.instagram.url }}" *ngIf="prismic"><i class='bx bxl-instagram'></i></a></li>
                        <li><a target="_blank" href="{{ prismic.linked_in.url }}" *ngIf="prismic"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="header-partner">
                    <div *ngIf="prismic" [innerHTML]="prismic.middle_cta"></div>
                    <a href="javascript:void( window.open( 'https://form.jotform.com/233384721305149', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) )">Apply Now</a>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="header-call">
                    <ul>
                        <li>
                            <span style="color:#000;">Call us</span>&nbsp;&nbsp;<span style="font-size:20px;color:#000" *ngIf="prismic">{{ prismic.phone_number }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<nav class="navbar navbar-expand-lg navbar-light sticky-top">
    <a class="navbar-brand" routerLink="/" href="#home"><img src="{{ prismic.company_logo.url }}" alt="Logo" *ngIf="prismic"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav" *ngIf="prismic">
            
            <li class="nav-item"><a class="nav-link" href="#pledge">{{ prismic.pledge }}</a></li>

            <li class="nav-item"><a class="nav-link" href="#benefits">{{ prismic.benefits }}</a></li>
            
            <li class="nav-item"><a class="nav-link" href="#about">{{ prismic.about }}</a></li>

            <li class="nav-item"><a class="nav-link" href="#team">{{ prismic.team }}</a></li>

            <li class="nav-item"><a class="nav-link" href="#faq">{{ prismic.faq }}</a></li>

            <li class="nav-item"><a class="nav-link" href="#contact">{{ prismic.contact }}</a></li>

            <li class="nav-item"><a class="nav-link" href="#articles">{{ prismic.articles }}</a></li>
        </ul>
    </div>

    <div class="side-nav">
        <a class="side-nav-right cd-signup" href="javascript:void( window.open( 'https://form.jotform.com/233384721305149', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) )"><i class='bx bx-link-external'></i>Apply Now</a>
    </div>
</nav>