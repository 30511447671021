import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  public event(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }

  // New method for tracking page views
  public trackPageView(url: string) {
    gtag('config', 'G-ZJE1ZL30TL', {
      'page_path': url
    });
  }
}